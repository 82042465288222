import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@/components/ui/badge.tsx';
import { Icons } from '@/components/ui/icons.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';

import { ProductItem } from '@/pages/checkout/components/product-item.tsx';

import { formatPrice } from '@/utils/format-price.ts';
import { getMerchantData } from '@/utils/get-merchant-data.ts';

import { OrderLine, ShippingOption } from '@/core/entities';

import fallbackImage from '/fallback.png';

interface NextDeliveryCardProps extends HTMLAttributes<HTMLDivElement> {
  products: OrderLine[];
  shippingOptions: ShippingOption[] | undefined;
  orderTotal: number;
}

export const DeliveryProductInfo: FC<NextDeliveryCardProps> = ({
  products,
  shippingOptions,
  className,
  orderTotal
}) => {
  const { t } = useTranslation("translation");

  const discount = products.find((product) => !product.merchant_data);
  const discountPrice = discount ? discount.unit_price / 100 : 0;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const shippingCost: number = shippingOptions ? shippingOptions[0].price : 0;

  return (
    <Card className={className}>
      <CardHeader className={"flex justify-between items-center flex-row"}>
        <div className={"space-y-1.5"}>
          <CardTitle>{t("yourOrder")}</CardTitle>
          <CardDescription>{t("theCapsulesAreShippedToday")}</CardDescription>
        </div>
      </CardHeader>

      <CardContent className={"space-y-6"}>
        {products.map((product) => {
          console.log(product);
          const merchantData = getMerchantData(product.merchant_data || "");

          if (!merchantData) {
            return null;
          }

          return (
            <ProductItem
              key={product.name}
              product={{
                image:
                  product.image_url === "" ? fallbackImage : product.image_url,
                name: product.name.replace('Prenumerationsorder', ''),
                quantity: product.quantity,
                price: (product.unit_price / 100).toString(),
                merchantData: merchantData,
              }}
            />
          );
        })}
      </CardContent>

      <Separator />

      <CardHeader>
        <CardTitle className={"font-semibold capitalize"}>
          {t("summary")}
        </CardTitle>
      </CardHeader>

      <CardContent>
        <SubscriptionSummary
          discount={{
            name: discount?.name || '',
            code: discount?.name || '',
            discount: discountPrice
          }}
          subtotal={orderTotal}
          shippingCost={shippingCost}
          total={orderTotal}
        />
      </CardContent>
    </Card>
  );
};

// interface AdditionalItemProps {
//   image: string;
//   label: string;
//   itemPoints: number;
//   saleType: "One Time" | "Subscription";
//   quantity: number;
// }
//
// const AdditionalItem: FC<AdditionalItemProps> = ({
//   image,
//   label,
//   itemPoints,
//   saleType,
//   // quantity,
// }) => {
//   return (
//     <div className={"flex gap-4 h-full justify-between items-center"}>
//       <div className={"flex gap-2 items-center"}>
//         <div className={"w-20"}>
//           <AspectRatio ratio={1}>
//             <img
//               src={image}
//               alt="coffe box"
//               className="rounded-lg  w-full h-full object-cover"
//             />
//           </AspectRatio>
//         </div>
//         <div>
//           <h1>
//             <span className={"font-semibold"}>{label}</span>
//           </h1>
//           <Badge variant="secondary">{saleType}</Badge>
//         </div>
//       </div>
//
//       <div className={"flex gap-8"}>
//         {/*<ItemQuantitySelector*/}
//         {/*  quantity={quantity}*/}
//         {/*  setQuantity={(quantity) => console.log(quantity)}*/}
//         {/*/>*/}
//         <ItemPoints points={itemPoints} />
//       </div>
//     </div>
//   );
// };
//
// interface ItemPointsProps {
//   points: number;
// }
//
// const ItemPoints: FC<ItemPointsProps> = ({ points }) => {
//   return (
//     <div className={"flex gap-2 items-center"}>
//       <span className={"text-base font-semibold"}>{points}</span>
//       <Icons.star className={"w-5 h-5"} />
//     </div>
//   );
// };

interface SubscriptionSummaryProps {
  subtotal: number;
  discount?: {
    name: string;
    code: string;
    discount: number;
  };
  total: number;
  shippingCost: number;
}

const SubscriptionSummary: FC<SubscriptionSummaryProps> = ({
  subtotal,
  discount,
  total,
  shippingCost,
}) => {
  const { t } = useTranslation();

  const newMemberBonusPoints = 10;
  const points = Math.round(total / 10 / 100) + newMemberBonusPoints;

  const totalWithCalculatedShipping = total + shippingCost;

  return (
    <div className={"space-y-4"}>
      <div className={"flex justify-between text-xs sm:text-sm font-semibold"}>
        <p className={"capitalize"}>{t("subTotal")}</p>
        <p>{formatPrice(subtotal)}</p>
      </div>

      <div className={"flex justify-between text-xs sm:text-sm font-semibold"}>
        <p className={"capitalize"}>{t("shipping")}</p>
        <p>{formatPrice(shippingCost)}</p>
      </div>

      {discount?.name && (
        <div
          className={
            "flex justify-between items-center text-xs sm:text-sm font-semibold"
          }
        >
          <div>
            <div className={'flex gap-2 items-center'}>
            <p className={"capitalize"}>{t("discount")}</p>
              <Badge variant={"default"}>{discount.code}</Badge>
            </div>
          </div>
          <p>
            {discount.discount} {discount.discount ? 'kr' : ''}
          </p>
        </div>
      )}
      <div className={"flex justify-between text-xs sm:text-sm font-semibold"}>
        <p className={"capitalize"}>{t("usedPoints")}</p>
        <span className={"flex gap-2 items-center"}>
          {points} <Icons.star className={"w-4 h-4"} />
        </span>
      </div>
      <div className={"flex justify-between text-xs sm:text-lg font-semibold"}>
        <p>Total</p>
        <p className={"font-black text-2xl"}>
          {formatPrice(totalWithCalculatedShipping)}
        </p>
      </div>
    </div>
  );
};
